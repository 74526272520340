import Vue from "vue";
import VueRouter from "vue-router";
import NotFound from "@/views/layout/NotFound.vue";
import Unauthorized from "@/views/layout/Unauthorized.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        redirect: () => {
            const loggedIn = localStorage.getItem("user");
            if (loggedIn) {
                return { name: "LoginHome" };
            } else {
                return { name: "Login" };
            }
        },
    },
    {
        path: "/accueil",
        name: "LoginHome",
        component: () => import("@/views/Home.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/404",
        name: "404",
        component: NotFound,
    },
    {
        path: "/403",
        name: "403",
        component: Unauthorized,
    },
    {
        path: "*",
        redirect: { name: "404" },
    },
    {
        path: "/connexion",
        name: "Login",
        component: () => import("@/views/auth/Login.vue"),
    },
    {
        path: "/inscription",
        name: "Register",
        component: () => import("@/views/auth/Register.vue"),
    },
    {
        path: "/mot-de-passe-oublie",
        name: "ForgottenPassword",
        component: () => import("@/views/auth/ForgottenPassword.vue"),
    },
    {
        path: "/reset-password/:token/:email",
        name: "ResetPassword",
        component: () => import("@/views/auth/ResetPassword.vue"),
        props: true,
    },
    {
        path: "/mentions-legales",
        name: "LegalInformation",
        component: () => import("@/views/layout/LegalInformation.vue"),
    },
    {
        path: "/cgu",
        name: "TermsOfUse",
        component: () => import("@/views/layout/TermsOfUse.vue"),
    },
    {
        path: "/mon-compte",
        name: "MyAccount",
        component: () => import("@/views/auth/MyAccount.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/campagnes/ajout",
        name: "CreateCampaign",
        component: () => import("@/views/campaigns/Create.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/campagnes/:campaignId/association/:associationId",
        name: "AssociationCampaignForm",
        component: () => import("@/views/MembershipForm.vue"),
        meta: {
            requiresAuth: true,
        },
        props: true,
    },
    {
        path: "/campagnes/:campaignId",
        name: "Campaign",
        component: () => import("@/views/campaigns/Show.vue"),
        meta: {
            requiresAuth: true,
        },
        props: true,
    },
    {
        path: "/associations/:associationId",
        name: "Association",
        component: () => import("@/views/associations/Show.vue"),
        meta: {
            requiresAuth: true,
        },
        props: true,
    },
    {
        path: "/associations/:associationId/edit",
        name: "UpdateAssociation",
        component: () => import("@/views/associations/Update.vue"),
        meta: {
            requiresAuth: true,
        },
        props: true,
    },
    {
        path: "/federations/:federationId",
        name: "Federation",
        component: () => import("@/views/federations/Show.vue"),
        meta: {
            requiresAuth: true,
        },
        props: true,
    },
    {
        path: "/federations/:federationId/edit",
        name: "UpdateFederation",
        component: () => import("@/views/federations/Update.vue"),
        meta: {
            requiresAuth: true,
        },
        props: true,
    },
    {
        path: "/members/:memberId",
        name: "Member",
        component: () => import("@/views/members/Show.vue"),
        meta: {
            requiresAuth: true,
        },
        props: true,
    },
    {
        path: "/members/:memberId/edit",
        name: "UpdateMember",
        component: () => import("@/views/members/Update.vue"),
        meta: {
            requiresAuth: true,
        },
        props: true,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

//Authentification
router.beforeEach((to, from, next) => {
    const loggedIn = localStorage.getItem("user");

    if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
        return next({ name: "Login" });
    }

    return next();
});

export default router;
