import Vue from "vue";
import Vuex from "vuex";
import AuthModule from "@/store/AuthModule.store";
import OhmeModule from "@/store/OhmeModule.store";
import UtilsModule from "@/store/UtilsModule.store";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        drawer: false,
    },
    getters: {},
    mutations: {
        TOGGLE_DRAWER(state) {
            state.drawer = !state.drawer;
        },

        SET_DRAWER(state, payload) {
            state.drawer = payload;
        },
    },
    actions: {},
    modules: {
        auth: AuthModule,
        ohme: OhmeModule,
        utils: UtilsModule
    },
});
