import axios from "axios";

const state = {
    homePage: "Home",
    user: null,
    navbarLinks: [],
    publicNavbarLinks: [
        {
            name: "auth.login",
            to: "Login",
        },
        {
            name: "auth.register",
            to: "Register",
        },
    ],
};

const getters = {
    loggedIn(state) {
        return !!state.user;
    },

    navbarLinks(state, getters) {
        if (getters.loggedIn) {
            return state.navbarLinks;
        } else {
            return state.publicNavbarLinks;
        }
    },
};

const mutations = {
    SET_USER_DATA(state, userData) {
        state.user = userData;
        localStorage.setItem("user", JSON.stringify(state.user));
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${state.user.access_token}`;
    },

    CLEAR_USER_DATA() {
        localStorage.removeItem("user");
        location.reload();
    },
};

const actions = {
    register({ commit }, data) {
        return axios
            .post(process.env.VUE_APP_BACKEND_URL + "auth/register", data)
            .then(({ data }) => {
                commit("SET_USER_DATA", data);
            });
    },

    login({ commit }, form) {
        var data = {
            grant_type: "password",
            client_id: process.env.VUE_APP_CLIENT_ID,
            client_secret: process.env.VUE_APP_CLIENT_SECRET,
            username: form.email,
            password: form.password,
            captcha: form.captcha,
        };

        return axios
            .post(process.env.VUE_APP_BACKEND_URL + "auth/login", data)
            .then(({ data }) => {
                delete data.expires_in;
                delete data.refresh_token;
                delete data.token_type;
                commit("SET_USER_DATA", data);
            });
    },

    logout({ commit }) {
        commit("CLEAR_USER_DATA");
    },

    forgottenPassword({ commit }, form) {
        commit;

        return axios
            .post(process.env.VUE_APP_BACKEND_URL + "auth/password/email", form)
            .then(({ data }) => {
                if (!data) {
                    throw new Error("Email not send");
                }
            });
    },

    resetPassword({ commit }, form) {
        commit;

        var data = {
            username: form.email,
            password: form.password,
            password_confirmation: form.password,
        };

        return axios
            .post(
                process.env.VUE_APP_BACKEND_URL +
                    "auth/password/reset?token=" +
                    form.token +
                    "&email=" +
                    form.email,
                data
            )
            .then(({ data }) => {
                if (!data) {
                    throw new Error("Reset error");
                }
            });
    },

    getUser(commit) {
        commit;
        return axios.get(process.env.VUE_APP_BACKEND_URL + "my-account");
    },

    updateInformation(commit, form) {
        commit;
        return axios.post(
            process.env.VUE_APP_BACKEND_URL + "my-account/information",
            form
        );
    },

    updatePassword(commit, form) {
        commit;
        return axios.post(
            process.env.VUE_APP_BACKEND_URL + "my-account/password",
            form
        );
    },

    deleteAccount({ commit }) {
        return axios
            .post(process.env.VUE_APP_BACKEND_URL + "my-account", {
                _method: "DELETE",
            })
            .then(() => {
                commit("CLEAR_USER_DATA");
            });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
