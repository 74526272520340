<template>
    <v-app-bar
        app
        elevate-on-scroll
        v-scroll="onScroll"
        color="primary"
        height="62px"
        dark
    >
        <v-toolbar-title class="mt-2 mb-1">
            <router-link :to="{ name: 'Home' }" :aria-label="name"
                ><v-img
                    :src="require('@/assets/logo.png')"
                    height="60px"
                    max-width="200px"
                    contain
                ></v-img
            ></router-link>
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <span v-if="$vuetify.breakpoint.mdAndUp" class="font-weight-bold">
        {{$t("title")}}
        </span>
        <v-spacer></v-spacer>

        <v-app-bar-nav-icon
            class="hidden-md-and-up"
            aria-label="Menu"
            @click="TOGGLE_DRAWER"
        ></v-app-bar-nav-icon>

        <span v-for="(link, i) in navbarLinks" :key="i">
            <v-btn
                text
                :to="{ name: link.to }"
                class="font-weight-black mx-2 hidden-sm-and-down"
            >
                {{ $t(link.name) }}
            </v-btn>
        </span>

        <v-menu offset-y v-if="loggedIn">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    class="hidden-sm-and-down"
                    :aria-label="$t('account.myAccount')"
                    icon
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon>mdi-account-circle</v-icon>
                </v-btn>
            </template>

            <v-list>
                <v-list-item :to="{ name: 'MyAccount' }">
                    <v-list-item-title>
                        {{ $t("account.myAccount") }}
                    </v-list-item-title>
                </v-list-item>
                <v-list-item @click="logout">
                    <v-list-item-title>
                        {{ $t("auth.logout") }}
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";

export default {
    name: "Navbar",

    data() {
        return {
            isScrolling: false,
            name: process.env.VUE_APP_NAME,
        };
    },

    computed: {
        ...mapGetters({
            loggedIn: "auth/loggedIn",
            navbarLinks: "auth/navbarLinks",
        }),
    },

    methods: {
        ...mapMutations(["TOGGLE_DRAWER"]),

        onScroll() {
            const offset = window.pageYOffset;
            this.isScrolling = offset > 50;
        },

        logout() {
            this.$store.dispatch("auth/logout").then(() => {});
        },
    },
};
</script>

<style lang="scss" scoped>
.v-btn--active {
    &:before {
        opacity: 0 !important;
    }
}
</style>
