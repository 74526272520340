<template>
    <v-navigation-drawer v-model="drawer" app temporary>
        <v-list>
            <span v-for="(link, i) in navbarLinks" :key="i">
                <v-list-item @click="onClick($event, link)">
                    <v-list-item-title v-text="$t(link.name)" />
                </v-list-item>
            </span>

            <v-list-group v-if="loggedIn" no-action>
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title>
                            <v-icon>mdi-account-circle</v-icon>
                        </v-list-item-title>
                    </v-list-item-content>
                </template>

                <v-list-item :to="{ name: 'MyAccount' }">
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $t("account.myAccount") }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item @click="logout">
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $t("auth.logout") }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
    name: "Drawer",

    computed: {
        ...mapGetters({
            loggedIn: "auth/loggedIn",
            navbarLinks: "auth/navbarLinks",
        }),

        drawer: {
            get() {
                return this.$store.state.drawer;
            },
            set(val) {
                this.SET_DRAWER(val);
            },
        },
    },

    methods: {
        ...mapMutations(["SET_DRAWER"]),

        onClick(e, item) {
            e.stopPropagation();
            this.$router.push({ name: item.to });
            this.SET_DRAWER(false);
        },

        logout() {
            this.$store.dispatch("auth/logout").then(() => {});
        },
    },
};
</script>
