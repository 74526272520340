<template>
    <v-app >
        <app-navbar></app-navbar>
        <app-drawer></app-drawer>

        <v-main class="px-5 grey lighten-4">
            <router-view class="ma-1 mb-4 mt-4" :key="$route.fullPath" />
        </v-main>

        <app-footer></app-footer>
    </v-app>
</template>

<script>
import AppNavbar from "@/components/layout/Navbar";
import AppDrawer from "@/components/layout/Drawer";
import AppFooter from "@/components/layout/Footer";

export default {
    name: "App",

    components: {
        AppNavbar,
        AppDrawer,
        AppFooter,
    },
};
</script>
