<template>
    <v-footer height="auto" class="grey lighten-3">
        <v-row class="mb-n1">
            <v-col class="text-center caption" cols="12">
                {{ name }} - © {{ new Date().getFullYear() }} -
                <a
                    href="https://www.batterie-fanfare.fr/contact/coordonnateur-administratif/"
                    class="black--text"
                    target="_blank"
                >
                    {{ $t("contact") }}
                </a>
                -
                <router-link
                    :to="{ name: 'LegalInformation' }"
                    class="black--text"
                >
                    {{ $t("layout.legalInformation") }}
                </router-link>
                -
                <router-link :to="{ name: 'TermsOfUse' }" class="black--text">
                    {{ $t("layout.cgu") }}
                </router-link>
            </v-col>
        </v-row>
    </v-footer>
</template>

<script>
export default {
    data() {
        return {
            name: process.env.VUE_APP_NAME,
        };
    },
};
</script>
