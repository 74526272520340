import i18n from "@/i18n";

const state = {
    requiredRule: [(value) => !!value || i18n.t("form.required")],

    passwordRule: [
        (value) => !!value || i18n.t("form.required"),
        (value) =>
            (value || "").length >= 8 || i18n.t("form.password.tooShort"),
    ],

    emailRule: {
        required(value) {
            return !!value || i18n.t("form.required");
        },
        email(value) {
            const pattern = /[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z.]{2,15}/;

            return (
                !value || pattern.test(value) || i18n.t("form.email.invalid")
            );
        },
        errors(errors) {
            return (
                errors == undefined ||
                !errors.email ||
                i18n.t("form.email." + errors.email)
            );
        },
    },

    requiredSelectRule: [
        (value) => (!!value && value !== "") || i18n.t("form.required"),
    ],

    requiredBooleanSelectRule: [
        (value) => value === true || value === false || i18n.t("form.required"),
    ],

    floatNumberRule: [
        (value) =>
            (!!value &&
                !Number.isNaN(value) &&
                parseFloat(value) >= 0 &&
                (!value.toString().includes(".") ||
                    value.toString().split(".")[1].length <= 2)) ||
            i18n.t("form.requiredNumber"),
    ],

    requiredNumberRule: [
        (value) =>
            (!!value &&
                !Number.isNaN(value) &&
                parseFloat(value) > 0 &&
                !value.toString().includes(".")) ||
            i18n.t("form.requiredNumber"),
    ],

    percentNumberRule: [
        (value) =>
            (true &&
                (!value ||
                    (!Number.isNaN(value) &&
                        !value.toString().includes(".") &&
                        parseInt(value) > 0 &&
                        parseInt(value) < 100))) ||
            i18n.t("form.requiredNumber"),
    ],

    phoneRule: {
        required(value) {
            return !!value || i18n.t("form.required");
        },
        phone(value) {
            const pattern = /^(([+][0-9][0-9])|0)([0-9]{9})$/;
            return !value || pattern.test(value.replace(/\s+/g, '')) || i18n.t("form.phone");
        },
    },

    siretRule: [
        (value) =>
            (true &&
                (!value ||
                    (!Number.isNaN(value) &&
                        !value.toString().includes(".") &&
                        value.toString().length == 14 &&
                        parseInt(value) > 0))) ||
            i18n.t("form.siret"),
    ],

    requiredYearRule: [
        (value) =>
            (true &&
                    !Number.isNaN(value) &&
                    parseInt(value) >= 1990 &&
                    parseInt(value) <= ((new Date()).getFullYear())+1) ||
            i18n.t("form.invalidYear"),
    ],
};

const getters = {};

const mutations = {};

const actions = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
