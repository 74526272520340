import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@mdi/font/css/materialdesignicons.css";
import i18n from "./i18n";
import axios from "axios";

import Nl2br from "vue-nl2br";
Vue.component("nl2br", Nl2br);

Vue.config.productionTip = false;

import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_GOOGLE_CAPTCHA_CLIENT,
    loaderOptions: {
        autoHideBadge: true,
    },
});

new Vue({
    router,
    store,
    vuetify,
    i18n,
    created() {
        const userString = localStorage.getItem("user");
        if (userString) {
            this.$store.commit("auth/SET_USER_DATA", JSON.parse(userString));
        }
        axios.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response.status === 401) {
                    this.$store.dispatch("auth/logout");
                }
                return Promise.reject(error);
            }
        );
    },
    render: (h) => h(App),
}).$mount("#app");
