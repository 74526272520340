import axios from "axios";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    home() {
        return axios.get(process.env.VUE_APP_BACKEND_URL + "home");
    },

    getAssociationCampaign(commit, { campaignId, associationId }) {
        commit;
        return axios.get(
            process.env.VUE_APP_BACKEND_URL +
                "campaigns/" +
                campaignId +
                "/association/" +
                associationId
        );
    },

    setAssociationCampaign(commit, { form, campaignId, associationId }) {
        commit;
        return axios.post(
            process.env.VUE_APP_BACKEND_URL +
                "campaigns/" +
                campaignId +
                "/association/" +
                associationId,
            form
        );
    },

    getCampaigns(commit) {
        commit;
        return axios.get(process.env.VUE_APP_BACKEND_URL + "campaigns");
    },

    getCampaign(commit, campaignId) {
        commit;
        return axios.get(
            process.env.VUE_APP_BACKEND_URL + "campaigns/" + campaignId
        );
    },

    addCampaign(commit, form) {
        commit;
        return axios.post(process.env.VUE_APP_BACKEND_URL + "campaigns", form);
    },

    getAssociations(commit) {
        commit;
        return axios.get(process.env.VUE_APP_BACKEND_URL + "associations");
    },

    getCreateCampaign(commit) {
        commit;
        return axios.get(process.env.VUE_APP_BACKEND_URL + "campaigns/create");
    },

    getAssociation(commit, associationId) {
        commit;
        return axios.get(
            process.env.VUE_APP_BACKEND_URL + "associations/" + associationId
        );
    },

    getAssociationMembershipStatus(commit, { associationId, year }) {
        commit;
        return axios.get(
            process.env.VUE_APP_BACKEND_URL +
                "associations/" +
                associationId +
                "/membership-status/" +
                year
        );
    },

    getAssociationBfMag(commit, { associationId, year }) {
        commit;
        return axios.get(
            process.env.VUE_APP_BACKEND_URL +
                "associations/" +
                associationId +
                "/bf-mag/" +
                year
        );
    },

    getUpdateAssociation(commit, associationId) {
        commit;
        return axios.get(
            process.env.VUE_APP_BACKEND_URL + "associations/" + associationId + "/update"
        );
    },

    updateAssociation(commit, { form, associationId }) {
        commit;
        return axios.post(
            process.env.VUE_APP_BACKEND_URL + "associations/" + associationId,
            form
        );
    },

    getCreateAssociationHistoryLine(commit, associationId) {
        commit;
        return axios.get(
            process.env.VUE_APP_BACKEND_URL +
                "associations/" +
                associationId +
                "/history/create"
        );
    },

    createAssociationHistoryLine(commit, { form, associationId }) {
        commit;
        return axios.post(
            process.env.VUE_APP_BACKEND_URL +
                "associations/" +
                associationId +
                "/history",
            form
        );
    },

    updateAssociationHistoryLine(
        commit,
        { form, associationId, historyLineId }
    ) {
        commit;
        return axios.post(
            process.env.VUE_APP_BACKEND_URL +
                "associations/" +
                associationId +
                "/history/" +
                historyLineId,
            form
        );
    },

    deleteAssociationHistoryLine(commit, { associationId, historyLineId }) {
        commit;
        return axios.post(
            process.env.VUE_APP_BACKEND_URL +
                "associations/" +
                associationId +
                "/history/" +
                historyLineId,
            {
                _method: "DELETE",
            }
        );
    },

    getFederation(commit, federationId) {
        commit;
        return axios.get(
            process.env.VUE_APP_BACKEND_URL + "federations/" + federationId
        );
    },

    getUpdateFederation(commit, federationId) {
        commit;
        return axios.get(
            process.env.VUE_APP_BACKEND_URL +
                "federations/" +
                federationId +
                "/update"
        );
    },

    updateFederation(commit, { form, federationId }) {
        commit;
        return axios.post(
            process.env.VUE_APP_BACKEND_URL + "federations/" + federationId,
            form
        );
    },

    getMember(commit, memberId) {
        commit;
        return axios.get(
            process.env.VUE_APP_BACKEND_URL + "members/" + memberId
        );
    },

    updateMember(commit, { form, memberId }) {
        commit;
        return axios.post(
            process.env.VUE_APP_BACKEND_URL + "members/" + memberId,
            form
        );
    },

    updateMemberAssociationData(commit, { form, memberId, associationId }) {
        commit;
        return axios.post(
            process.env.VUE_APP_BACKEND_URL +
                "members/" +
                memberId +
                "/association/" +
                associationId,
            form
        );
    },

    getCreateMemberHistoryLine(commit, memberId) {
        commit;
        return axios.get(
            process.env.VUE_APP_BACKEND_URL +
                "members/" +
                memberId +
                "/history/create"
        );
    },

    createMemberHistoryLine(commit, { form, memberId }) {
        commit;
        return axios.post(
            process.env.VUE_APP_BACKEND_URL +
                "members/" +
                memberId +
                "/history",
            form
        );
    },

    updateMemberHistoryLine(commit, { form, memberId, historyLineId }) {
        commit;
        return axios.post(
            process.env.VUE_APP_BACKEND_URL +
                "members/" +
                memberId +
                "/history/" +
                historyLineId,
            form
        );
    },

    deleteMemberHistoryLine(commit, { memberId, historyLineId }) {
        commit;
        return axios.post(
            process.env.VUE_APP_BACKEND_URL +
                "members/" +
                memberId +
                "/history/" +
                historyLineId,
            {
                _method: "DELETE",
            }
        );
    },

    getCreateMemberAnnualHistoryLine(commit, memberId) {
        commit;
        return axios.get(
            process.env.VUE_APP_BACKEND_URL +
                "members/" +
                memberId +
                "/annual-history/create"
        );
    },

    createMemberAnnualHistoryLine(commit, { form, memberId }) {
        commit;
        return axios.post(
            process.env.VUE_APP_BACKEND_URL +
                "members/" +
                memberId +
                "/annual-history",
            form
        );
    },

    updateMemberAnnualHistoryLine(commit, { form, memberId, historyLineId }) {
        commit;
        return axios.post(
            process.env.VUE_APP_BACKEND_URL +
                "members/" +
                memberId +
                "/annual-history/" +
                historyLineId,
            form
        );
    },

    deleteMemberAnnualHistoryLine(commit, { memberId, historyLineId }) {
        commit;
        return axios.post(
            process.env.VUE_APP_BACKEND_URL +
                "members/" +
                memberId +
                "/annual-history/" +
                historyLineId,
            {
                _method: "DELETE",
            }
        );
    },

    updateCampaign(commit, { form, campaignId }) {
        commit;
        return axios.post(
            process.env.VUE_APP_BACKEND_URL + "campaigns/" + campaignId,
            form
        );
    },

    deleteCampaign(commit, campaignId) {
        commit;
        return axios.post(
            process.env.VUE_APP_BACKEND_URL + "campaigns/" + campaignId,
            {
                _method: "DELETE",
            }
        );
    },

    getUsers(commit) {
        commit;
        return axios.get(process.env.VUE_APP_BACKEND_URL + "users");
    },

    deleteUser(commit, userId) {
        commit;
        return axios.post(process.env.VUE_APP_BACKEND_URL + "users/" + userId, {
            _method: "DELETE",
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
