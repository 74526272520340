import Vue from "vue";
import Vuetify from "vuetify/lib";
import fr from "vuetify/es5/locale/fr";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: "#1e22aa",
                secondary: "#424242",
                accent: "#34E4EA",
                error: "#c60014",
                info: "#0EB1D2",
                success: "#4a9e24",
                warning: "#fca401",
            },
        },
    },
    lang: {
        locales: { fr },
        current: "fr",
    },
});
